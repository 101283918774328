import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "*",
    component: resolve => import('../views/error404.vue')
  },
  {
    path: '/',
    component: () => import('../views/page/pc/index.vue'),
    children: [
      {
        path: "",
        name: "home",
        component: () => import('../views/page/pc/bbs/home.vue'),
        meta: {
          title: "home"
        }
      }]
  },
  {
    path: '/qq',
    component: () => import('../views/page/pc/index.vue'),
    children: [
      {
        path: "",
        name: "qq-home",
        component: () => import('../views/page/pc/bbs/home.vue'),
        meta: {
          title: "home"
        }
      }]
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: "smallsnail-wh"
    },
    component: () => import('../views/login.vue')
  },
  {
    path: '/mobile/login',
    name: 'mobil-login',
    meta: {
      title: "smallsnail-wh"
    },
    component: () => import('../views/mlogin.vue')
  },
  {
    path: '/page',
    meta: {
      title: "smallsnail-wh"
    },
    component: () => import('../views/page/pc/index.vue'),
    children: [
      {
        path: "home",
        name: "page-home",
        component: () => import('../views/page/pc/bbs/home.vue')
      }, {
        path: "home/:title",
        name: "page-home-title",
        component: () => import('../views/page/pc/bbs/home.vue')
      }, {
        path: "detail/:id",
        name: "page-detail-id",
        component: () => import('../views/page/pc/bbs/detail.vue')
      }, {
        path: "card/:id",
        name: "page-card-id",
        component: () => import('../views/page/pc/bbs/card.vue')
      }, {
        path: "messages",
        name: "page-messages",
        component: () => import('../views/page/pc/bbs/messages.vue')
      }, {
        path: "user",
        name: "page-user",
        component: () => import('../views/page/pc/bbs/user-info.vue')
      }, {
        path: "user/:id",
        name: "page-user-id",
        component: () => import('../views/page/pc/user-page.vue')
      }]
  },
  {
    path: '/mobile',
    meta: {
      title: "smallsnail-wh"
    },
    component: () => import('../views/page/mobile/index.vue'),
    children: [
      {
        path: "",
        name: "mobile-home",
        component: () => import('../views/page/mobile/home.vue')
      }, {
        path: "home/:title",
        name: "mobile-home-title",
        component: () => import('../views/page/mobile/home.vue')
      }, {
        path: "detail/:id",
        name: "mobile-detail-id",
        component: () => import('../views/page/mobile/detail.vue')
      }, {
        path: "card/:id",
        name: "mobile-card-id",
        component: () => import('../views/page/mobile/card.vue')
      }, {
        path: "messages",
        name: "mobile-messages",
        component: () => import('../views/page/mobile/messages.vue')
      }, {
        path: "article",
        name: "mobile-article",
        component: () => import('../views/page/mobile/article.vue')
      }, {
        path: "article/detail/:id",
        name: "mobile-article-detail",
        component: () => import('../views/page/mobile/article-detail.vue')
      }, {
        path: "user/:id",
        name: "mobile-user-page",
        component: () => import('../views/page/mobile/user-page.vue')
      }]
  },
  {
    path: '/base',
    meta: {
      title: "base",
      requiresAuth: true
    },
    component: () => import('../views/console/base.vue'),
    children: [
      {
        path: "",
        name: "welcome",
        component: () => import('../views/console/welcome.vue')
      }, {
        path: "menu",
        name: "menu",
        component: () => import('../views/console/sys/menu.vue')
      }, {
        path: "role",
        name: "role",
        component: () => import('../views/console/sys/role.vue')
      }, {
        path: "user",
        name: "user",
        component: () => import('../views/console/sys/user.vue')
      }, {
        path: "email",
        name: "email",
        component: () => import('../views/console/email/email.vue')
      }, {
        path: "card",
        name: "cardmanage",
        component: () => import('../views/console/postcard/card.vue')
      }, {
        path: "i-edit",
        name: "interest-edit",
        component: () => import('../views/console/interest/interest-edit.vue')
      }, {
        path: "i-create",
        name: "interest-create",
        component: () => import('../views/console/interest/interest-create.vue')
      }, {
        path: "i-delete",
        name: "interest-delete",
        component: () => import('../views/console/interest/interest-delete.vue')
      }, {
        path: "banner",
        name: "banner",
        component: () => import('../views/console/banner/banner.vue')
      }, {
        path: "article",
        name: "article",
        component: () => import('../views/console/article/article.vue')
      }, {
        path: "log",
        name: "log",
        component: () => import('../views/console/log/log.vue')
      }, {
        path: "notice",
        name: "notice",
        component: () => import('../views/console/notice/notice.vue')
      }]
  },
  {
    path: '/article',
    meta: {
      title: "smallsnail-wh"
    },
    component: () => import('../views/page/pc/index.vue'),
    children: [
      {
        path: "",
        name: "article-home",
        component: () => import('../views/page/pc/blog/home.vue')
      }, {
        path: "create",
        name: "article-create",
        component: () => import('../views/page/pc/blog/create-article.vue')
      }, {
        path: "create/success",
        name: "article-create-success",
        component: () => import('../views/page/pc/blog/create-article-success.vue')
      }, {
        path: "detail/:id",
        name: "article-detail-id",
        component: () => import('../views/page/pc/blog/article-detail.vue')
      }, {
        path: "user",
        name: "article-user",
        component: () => import('../views/page/pc/blog/user-article.vue')
      }, {
        path: "update/:id",
        name: "article-update-id",
        component: () => import('../views/page/pc/blog/update-article.vue')
      }]
  },
]

const router = new VueRouter({
  mode: "history",
  routes: routes
})

router.beforeEach((to, from, next) => {
  let toMobile = to.fullPath.startsWith('/mobile');
  let isMobile = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );

  let isAuth = (to.path == '/qq' || to.path == '/github') ? true : false;
  if(isAuth && isMobile){
    next({
      path: '/mobile',
      query: to.query
    });
    return;
  }else if(isAuth && !isMobile){
    next({
      path: '/',
      query: to.query
    });
    return;
  }

  if(!toMobile && isMobile){
    next('/mobile');
  }else if(toMobile && !isMobile){
    next('/');
  }
    
  next();
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
